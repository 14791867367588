import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { Button, Icon } from '~components';
import useAuth from '~hooks/use-auth';
import { useSettings } from '~hooks/use-settings';
import { useBodyScroll } from '~hooks/use-body-scroll';
import { usePageDimensions } from '~hooks/use-page-dim';
import { useResize } from '~hooks/use-resize';
import { useOutside } from '~source/ui/hooks/use-outside';

import $ from './header.scss';

interface Route {
    name: string;
    path: string;
    condition?: boolean;
}

export function Header(): JSX.Element {
    const history = useHistory();
    const [pageWidth] = usePageDimensions();
    const { isAuthorized, logout } = useAuth();
    const settings = useSettings();

    const [sticky, setSticky] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [navHeight, setNavHeight] = useState(0);
    const headerTop = useRef<HTMLDivElement>(null);
    const navigation = useRef<HTMLDivElement>(null);

    useResize(() => {
        setNavHeight(navigation.current?.getBoundingClientRect().height ?? 0);
    }, [headerTop, setNavHeight]);

    useBodyScroll(
        (scrollY) => {
            setSticky(scrollY > (headerTop.current?.getBoundingClientRect().height ?? 0));
        },
        [headerTop, pageWidth],
    );

    const routes = useMemo(
        () =>
            [
                {
                    name: 'Home',
                    path: '/',
                    condition: true,
                },
                {
                    name: 'Kijk live',
                    path: '/live',
                    condition: settings?.hasSubscription,
                },
                {
                    name: 'Wedstrijden/Events',
                    path: '/payperview',
                    condition: true,
                },
                {
                    name: 'Gemist',
                    path: '/missed',
                    condition: true,
                },
            ].filter((route) => route.condition) as Route[],
        [settings],
    );

    const onAccount = useCallback(() => history.push('/account'), [history]);
    const onLogin = useCallback(() => history.push('/login'), [history]);
    const onLogout = useCallback(() => logout(), [logout]);

    useEffect(() => {
        function bodyScrollLockListener() {
            // 864px = mobile header breakpoint (54em)
            if (window.outerWidth > 864) {
                clearAllBodyScrollLocks();
                setPopoverOpen(false);
            }
        }
        window.addEventListener('resize', bodyScrollLockListener);
        return () => window.removeEventListener('resize', bodyScrollLockListener);
    }, [setPopoverOpen]);

    const toggleDropdown = useCallback(
        (state?: boolean) => {
            const newState = state ?? !popoverOpen;
            if (newState === true) disableBodyScroll(document.body);
            else clearAllBodyScrollLocks();
            setPopoverOpen(newState);
        },
        [popoverOpen, setPopoverOpen],
    );

    const route2Match = useRouteMatch(routes[1].path);
    const activeRoute: boolean[] = [
        useRouteMatch(routes[0].path)?.isExact ?? false,
        routes[1].condition! ? !!route2Match : route2Match?.isExact ?? false,
        !!useRouteMatch(routes[2].path),
    ];

    const popover = useRef<HTMLDivElement>(null);
    useOutside(popover, () => popoverOpen && toggleDropdown(false));

    return (
        <header className={$.header}>
            <div ref={headerTop} className={$.headerTop}>
                <div>
                    <div>
                        <div className={$.headerLogo}>
                            <Link to='/' aria-label='Home'>
                                <Icon type='ziggoSportTotaal' size={32} />
                            </Link>
                        </div>
                        <div className={$.headerButtons}>
                            {isAuthorized ? (
                                <>
                                    <Button
                                        size='small'
                                        color='transparent'
                                        onClick={onAccount}
                                        className={$.desktop}
                                        label={
                                            <>
                                                <Icon type='ziggo' size={20} color='white' />
                                                <p>Mijn Ziggo Sport Totaal</p>
                                            </>
                                        }
                                    />
                                    <Button
                                        size='small'
                                        color='transparent'
                                        onClick={onLogout}
                                        className={$.desktop}
                                        label={
                                            <>
                                                <Icon type='user' size={20} className={$.userFix} />
                                                <span>Uitloggen</span>
                                            </>
                                        }
                                    />
                                    <div
                                        ref={popover}
                                        className={$.popover}
                                        data-open={popoverOpen}
                                    >
                                        {/* eslint-disable-next-line */}
                                        <p
                                            onClick={() => {
                                                toggleDropdown(false);
                                                onAccount();
                                            }}
                                        >
                                            Mijn Ziggo Sport Totaal
                                        </p>
                                        {/* eslint-disable-next-line */}
                                        <p
                                            onClick={() => {
                                                toggleDropdown(false);
                                                onLogout();
                                            }}
                                        >
                                            Uitloggen
                                        </p>
                                    </div>
                                    <Button
                                        size='small'
                                        color='transparent'
                                        onClick={() => toggleDropdown()}
                                        className={$.mobile}
                                        label={
                                            <>
                                                <Icon type='menu' size={20} color='white' />
                                                <p>Menu</p>
                                            </>
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        size='small'
                                        color='transparent'
                                        onClick={onLogin}
                                        label={
                                            <>
                                                <Icon type='user' size={14} />
                                                <span>Login</span>
                                            </>
                                        }
                                    />
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.ziggosport.nl/neem-ziggo-sport/'
                                    >
                                        <Button
                                            size='small'
                                            color='black'
                                            label={
                                                <span>
                                                    Neem Ziggo Sport Totaal{' '}
                                                    <Icon type='arrowRight' size={14} />
                                                </span>
                                            }
                                        />
                                    </a>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: sticky ? navHeight : 0 }} />
            <div ref={navigation} className={$.navigation + (sticky ? ` ${$.sticky}` : '')}>
                <div>
                    <div data-sticky={sticky} className={$.navigationLogo}>
                        <Icon type='ziggoSportTotaal' size={32} />
                    </div>
                    {routes.map((route, i) => (
                        <Link
                            key={route.path}
                            to={route.path}
                            data-active={activeRoute[i]}
                            className={$.navigationLink}
                        >
                            <p>{route.name}</p>
                            <div className={$.active} data-active={activeRoute[i]} />
                        </Link>
                    ))}
                </div>
            </div>
        </header>
    );
}
